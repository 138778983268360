import { graphql } from "gatsby";

import Layout from "../components/layout-main";
import Img from "gatsby-image";

/** @jsx jsx */
import { jsx } from "theme-ui";

const CareersPage = ({ data, location }) => {
  return (
    <Layout location={location} pageTitle="Careers">
      <section className="aw-accent">
        <div className="aw-content">
          <h1>Careers</h1>
          <div
            sx={{
              width: ["180px", "200px", "250px", "300px"],
              marginBottom: "20px",
            }}
          >
            <Img
              title="illustration of candles"
              alt="illustration of candles"
              fluid={data.image.childImageSharp.fluid}
            />
          </div>
          <h3>We help families find meaning after losing someone.</h3>
          <p>
            We're hiring for roles across Operations, Customer Success, Design,
            and Engineering. We offer competitive packages, a growth-focused
            environment, and unique benefits.
          </p>
          <p>
            Please reach out to learn more at{" "}
            <a
              href="mailto:careers@afterword.com"
              target="_blank"
              rel="noreferrer"
            >
              careers@afterword.com
            </a>
            .
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default CareersPage;

export const pageQuery = graphql`
  query {
    image: file(relativePath: { regex: "/candles.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
